import {Button, Flex, Input} from "antd";
import {useState} from "react";


const { TextArea } = Input;
export default function Editor(pops){
    const [content, setContent] = useState("");
    const click = (c) => {
        console.log(c)
        if (c === 1){
            setContent(content+"<b>粗体</b>")
        }else if (c === 2){
            setContent(content+"<a href=\"\">超链接</a>")
        }else if (c === 3){
            setContent(content+"<code>代码块</code>")
        }else if (c === 4){
            setContent(content+"<a href=\"https://t.me\">TG链接</a>")
        }
    }

    const change = (e) => {
        pops.onChange(e.target.value)
        setContent(e.target.value);
    }
    
    return <>
        <Flex wrap gap="small">
            <Button type="link" style={{width:30}} block onClick={()=>click(1)} >粗体</Button>
            <Button type="link" style={{width:50}} block onClick={()=>click(2)} >超链接</Button>
            <Button type="link" style={{width:50}} block onClick={()=>click(3)} >代码块</Button>
            <Button type="link" style={{width:50}} block onClick={()=>click(4)} >TG链接</Button>
        </Flex>
        <TextArea rows={8} value={content} placeholder="长度不能超过【1024】字符" onChange={change} maxLength={1024}/>
    </>
}