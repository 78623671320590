import {ajax} from "./request";

let baseUrl = 'https://upaytrx.top:7659';
// let baseUrl = 'http://127.0.0.1:7658';
if (process.env.REACT_APP_PROXY_URL === 'build') {
    baseUrl = 'http://83.229.121.190:7658';
}

//获取站点信息
export const ajaxLoginGetCode = param => ajax(baseUrl + '/admin/public/get-login-captcha', param, 'POST')
export const ajaxPublicLogin = param => ajax(baseUrl + '/admin/public/login', param, 'POST');

// export const ajaxTelegramGetInfo = param => ajax(baseUrl + '/admin/telegram/get-info', param, 'POST');

//充值

export const ajaxAdminRechargeGetPackageList = param => ajax(baseUrl + '/admin/recharge/get-package-list', param, 'POST');
export const ajaxAdminRechargeSetPackageSave = param => ajax(baseUrl + '/admin/recharge/set-package-save', param, 'POST');
export const ajaxAdminRechargeGetOrderList = param => ajax(baseUrl + '/admin/recharge/get-order-list', param, 'POST');

export const ajaxAdminTenancyGetPackageList = param => ajax(baseUrl + '/admin/tenancy/get-package-list', param, 'POST');
export const ajaxAdminTenancySetPackageSave = param => ajax(baseUrl + '/admin/tenancy/set-package-save', param, 'POST');
export const ajaxAdminTenancyGetOrderList = param => ajax(baseUrl + '/admin/tenancy/get-order-list', param, 'POST');
export const ajaxAdminTronGetOrderList = param => ajax(baseUrl + '/admin/tron/get-order-list', param, 'POST');
export const ajaxAdminTenancyGetOrderAdd = param => ajax(baseUrl + '/admin/tenancy/get-order-add', param, 'POST');
export const ajaxAdminTenancySetOrderStatus = param => ajax(baseUrl + '/admin/tenancy/set-order-status', param, 'POST');

export const ajaxAdminUserGetList = param => ajax(baseUrl + '/admin/user/get-list', param, 'POST');
export const ajaxAdminUserSetTelegramSend = param => ajax(baseUrl + '/admin/user/set-telegram-send', param, 'POST');
export const ajaxAdminUserSetRecharge = param => ajax(baseUrl + '/admin/user/set-recharge', param, 'POST');
export const ajaxAdminUserGetBalanceList = param => ajax(baseUrl + '/admin/user/get-balance-list', param, 'POST');

export const ajaxAdminBalanceGetList = param => ajax(baseUrl + '/admin/balance/get-list', param, 'POST');

//配置
export const ajaxAdminConfigGetInfo = param => ajax(baseUrl + '/admin/config/get-info', param, 'POST');
export const ajaxAdminConfigSetEd = param => ajax(baseUrl + '/admin/config/set-ed', param, 'POST');
export const ajaxAdminConfigGetGroupList = param => ajax(baseUrl + '/admin/config/get-group-list', param, 'POST');
export const ajaxAdminUserSetTelegramGroupSend = param => ajax(baseUrl + '/admin/config/set-telegram-group-send', param, 'POST');
export const ajaxAdminConfigGetGroupMessageInfo = param => ajax(baseUrl + '/admin/config/get-group-message-info', param, 'POST');
export const ajaxAdminConfigGetGroupMessageSave = param => ajax(baseUrl + '/admin/config/get-group-message-save', param, 'POST');

export const ajaxAdminOrganizationGetList = param => ajax(baseUrl + '/admin/organization/get-list', param, 'POST');
export const ajaxAdminOrganizationGetInfo = param => ajax(baseUrl + '/admin/organization/get-info', param, 'POST');
export const ajaxAdminOrganizationSetAdd = param => ajax(baseUrl + '/admin/organization/set-add', param, 'POST');
export const ajaxAdminOrganizationSetUserNamePasswordSave = param => ajax(baseUrl + '/admin/organization/set-user-name-password-save', param, 'POST');
export const ajaxAdminOrganizationGetBalanceList = param => ajax(baseUrl + '/admin/organization/get-balance-list', param, 'POST');
export const ajaxAdminOrganizationSetUserResetPasswordSave = param => ajax(baseUrl + '/admin/organization/set-user-reset-password-save', param, 'POST');



export const ajaxAdminOrganizationGetSyncTelegramInfo = param => ajax(baseUrl + '/admin/organization/get-sync-telegram-info', param, 'POST');
export const ajaxAdminOrganizationSetBalanceAddressSave = param => ajax(baseUrl + '/admin/organization/set-balance-address-save', param, 'POST');

export const ajaxAdminStatisticsGetInfo = param => ajax(baseUrl + '/admin/statistics/get-info', param, 'POST');


export const ajaxAdminConfigGetAdminInfo = param => ajax(baseUrl + '/admin/config/get-admin-info', param, 'POST');
export const ajaxAdminConfigSetAdminEd = param => ajax(baseUrl + '/admin/config/set-admin-ed', param, 'POST');